import {
  SimpleForm,
  SelectInput,
  TextInput,
  required,
  Labeled,
  WrapperField,
  ReferenceInput,
  NumberInput,
  email,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import FileInputAdmin from '../FileInputAdmin';
import { PARTNERS_FIELDS, TRANSFORM_TEXT } from '../../../shared/constants/const';

const styleInBlock = {
  border: '1px solid #cbd5fd',
  borderLeft: '8px solid #cbd5fd',
  padding: '20px',
  marginBottom: '20px',
};

const choicesPartnerCategory = Object.values(TRANSFORM_TEXT.partners);

export const PartnersForm = () => {
  return (
    <SimpleForm>
      <Labeled label={PARTNERS_FIELDS.title} fullWidth isRequired>
        <TextInput source='name' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={PARTNERS_FIELDS.category} fullWidth isRequired>
        <SelectInput
          source='tag'
          label='Select...'
          optionText='label'
          optionValue='value'
          choices={choicesPartnerCategory}
          fullWidth
          validate={[required()]}
          format={(v) => (v ? v : '')}
        />
      </Labeled>
      <FileInputAdmin source='partnerPhotos' label={PARTNERS_FIELDS.photos} multiple={true} validate={[required()]} />
      <Labeled label={PARTNERS_FIELDS.description} fullWidth isRequired>
        <TextInput source='description' validate={[required()]} multiline={true} minRows='3' maxRows='3' label='' inputProps={{style: { resize: 'vertical' }}}/>
      </Labeled>
      <Labeled label='Контакты' fullWidth>
        <WrapperField>
          <div style={styleInBlock}>
            <Labeled label={PARTNERS_FIELDS.phone} fullWidth isRequired>
              <TextInput source='contactPhone' label='' validate={[required()]} />
            </Labeled>
            <Labeled label={PARTNERS_FIELDS.email} fullWidth isRequired>
              <TextInput source='contactEmail' label='' validate={[required(), email()]} />
            </Labeled>
            <Labeled label={PARTNERS_FIELDS.site} fullWidth>
              <TextInput source='contactSite' label='' />
            </Labeled>
            <Labeled label={PARTNERS_FIELDS.telegram} fullWidth>
              <TextInput source='contactTelegram' label='' />
            </Labeled>
            <Labeled label={PARTNERS_FIELDS.whatsapp} fullWidth>
              <TextInput source='contactWhatsapp' label='' />
            </Labeled>
            <Labeled label={PARTNERS_FIELDS.vk} fullWidth>
              <TextInput source='contactVk' label='' />
            </Labeled>
            <Labeled label={PARTNERS_FIELDS.moreLink} fullWidth>
              <TextInput source='moreLink' label='' />
            </Labeled>
          </div>
        </WrapperField>
      </Labeled>
      <Labeled label={PARTNERS_FIELDS.experience} fullWidth isRequired>
        <NumberInput source='experience' validate={[required()]} label='' />
      </Labeled>
      <Labeled label={PARTNERS_FIELDS.video} fullWidth>
        <TextInput source='videoLink' label='' />
      </Labeled>
      <Labeled label={PARTNERS_FIELDS.products} fullWidth>
        <ArrayInput source='partnerTour' label='' fullWidth sx={styleInBlock}>
          <SimpleFormIterator disableReordering>
            <ReferenceInput source='id' reference='tours/published' perPage={Infinity} fullWidth>
              <SelectInput multiline optionText='title' optionValue='id' label='Select...' fullWidth />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </Labeled>
    </SimpleForm>
  );
};
