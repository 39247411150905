import { format } from 'date-fns';
import { ru } from "date-fns/locale";

export const getDatesTextTourFromDates = (
  startDate: string | null,
  endDate: string | null
) => {
  let result = "";
  if (startDate != null) {
    result = format(new Date(startDate), "dd MMM yyyy", {locale: ru});
  }
  if (endDate != null) {
    result += " - " + format(new Date(endDate), "dd MMM yyyy", {locale: ru});
  }
  return result;
};
