import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TourCard } from 'entities/tour/TourCard/TourCard';
import { TTour } from 'shared/types/tours.types';
import Slider, { Settings } from 'react-slick';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import classNames from 'classnames';
import { useSwipe } from 'shared/hooks/useSwipe';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';

const settings: Settings = {
  dots: true,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 3,
  nextArrow: <ButtonSlider right dark />,
  prevArrow: <ButtonSlider left dark />,
  responsive: [
    {
      breakpoint: 1123,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
};

type TUpcomingToursProps = {
  tours: TTour[];
};

const UpcomingTours = ({ tours }: TUpcomingToursProps) => {
  const [startSwipe, endSwipe, isSwipe] = useSwipe();
  const [openFull, setOpenFull] = useState(false);
  console.info(tours);
  const handleClickBtn = () => {
    setOpenFull((prev) => !prev);
  };

  return (
    <section className='upcoming-tours'>
      <div className='upcoming-tours__content'>
        <div className='upcoming-tours__content__title-container'>
          <h1 className='upcoming-tours__content__title-container__title'>
            <span>Ближайшие</span> туры и экскурсии
          </h1>
        </div>
        <div className='upcoming-tours__content__tours'>
          {!openFull ? (
            <Slider
              {...settings}
              className={classNames('upcoming-tours__content__tours__slider')}
              swipeEvent={startSwipe}
            >
              {tours.slice(0, 6).map((tour) => (
                <TourCard tour={tour} key={tour.id} endSwipe={endSwipe} isSwipe={isSwipe} />
              ))}
            </Slider>
          ) : (
            <div className='upcoming-tours__content__tours__list'>
              {tours.slice(0, 9).map((tour) => (
                <TourCard tour={tour} key={tour.id} endSwipe={endSwipe} isSwipe={isSwipe} />
              ))}
            </div>
          )}
        </div>
        {
          tours.length > 3 && 
          <button
          className={classNames('upcoming-tours__content__btn', { 'upcoming-tours__content__btn__active': openFull })}
          onClick={handleClickBtn}
        >
          {!openFull ? 'Показать все ближайшие' : 'Показывать меньше'} <ArrowButton />
        </button>
        }

      </div>
    </section>
  );
};

export default UpcomingTours;
