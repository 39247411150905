import React, { useRef, useState } from 'react';
import { useMediaQuery } from '../../../shared/hooks/useMatchMedia';
import Slider, { Settings } from 'react-slick';
import firstNewsImg from '../../../assets/images/trk/participants/8.png';
import { AboutMainInfoCardMobile, TAboutMainInfo } from './ClusterMembersNews/AboutMainInfoCardMobile';
import { selectIsBecomeMemberOpen, setIsBecomeMemberOpen } from '../../../manageStore/common/common.slice';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { AboutClusterNewsCardMobile } from './ClusterMembersNews/AboutClusterNewsCardMobile';
import { selectNewsList } from '../../../manageStore/news/newsSlice';
import InfoImage from '../../../assets/images/trk/participants/info.png';
import { MapModalInfo } from './MapModalInfo';
import Portal from '../../../components/Portal/Portal';
import BecomeMemberForm from './Members/BecomeMemberForm';
import { useHistory } from 'react-router-dom';
import { PointsOnMap } from '../../../shared/ui/Points/PointsOnMap';

const settingsSliderMobile: Settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1.25,
  slidesToScroll: 1,
};

const aboutNewsList: TAboutMainInfo[] = [
  {
    id: 1,
    label:
      'Самый северный в мире из крупных городов с населением более 184 тысяч человек. Строить город было решено, когда геологи обнаружили здесь крупные залежи полиметаллических руд. Их добывали еще в бронзовом веке — археологи нашли древнюю стоянку людей с первобытным оборудованием для переплавки меди.',
    title: `Хатанга — самый северный \n в мирекрупный город`,
    imag: firstNewsImg,
  },
  {
    id: 2,
    label:
      'Самый северный в мире из крупных городов с населением более 184 тысяч человек. Строить город было решено, когда геологи обнаружили здесь крупные залежи полиметаллических руд. Их добывали еще в бронзовом веке — археологи нашли древнюю стоянку людей с первобытным оборудованием для переплавки меди.',
    title: `Хатанга — самый северный \n в мирекрупный город`,
    imag: firstNewsImg,
  },
  {
    id: 3,
    label:
      'Самый северный в мире из крупных городов с населением более 184 тысяч человек. Строить город было решено, когда геологи обнаружили здесь крупные залежи полиметаллических руд. Их добывали еще в бронзовом веке — археологи нашли древнюю стоянку людей с первобытным оборудованием для переплавки меди.',
    title: `Хатанга — самый северный \n в мирекрупный город`,
    imag: firstNewsImg,
  },
  {
    id: 4,
    label:
      'Самый северный в мире из крупных городов с населением более 184 тысяч человек. Строить город было решено, когда геологи обнаружили здесь крупные залежи полиметаллических руд. Их добывали еще в бронзовом веке — археологи нашли древнюю стоянку людей с первобытным оборудованием для переплавки меди.',
    title: `Хатанга — самый северный \n в мирекрупный город`,
    imag: firstNewsImg,
  },
  {
    id: 5,
    label:
      'Самый северный в мире из крупных городов с населением более 184 тысяч человек. Строить город было решено, когда геологи обнаружили здесь крупные залежи полиметаллических руд. Их добывали еще в бронзовом веке — археологи нашли древнюю стоянку людей с первобытным оборудованием для переплавки меди.',
    title: `Хатанга — самый северный \n в мирекрупный город`,
    imag: firstNewsImg,
  },
];

export const AboutMainSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(true);
  const isOpen = useSelector(selectIsBecomeMemberOpen);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);
  const handelPortal = () => {
    dispatch(setIsBecomeMemberOpen(!isOpen));
  };
  const news = useSelector(selectNewsList);
  const [currentSlider, setCurrentSlider] = useState(0);

  const slickTrackRef = useRef<{
    node: HTMLDivElement | null;
  }>({ node: null });

  const handleChangeSlide = (prev: number, next: number) => {
    setCurrentSlider(next);
  };

  const handleInitSlider = () => {
    slickTrackRef.current.node = document.querySelector('.cluster-member__news__slider .slick-track');
    handleChangeSlide(0, 0);
  };
  const locations = history.location.pathname.split('/');
  const currentPath = locations[locations.length - 1];

  return (
    <>
      <div
        className={classNames({
          'new-container': currentPath === 'trk-info',
          'old-container': currentPath === 'trk-info-old',
        })}
      >
        <div className='inner-about-content'>
          <div className='container inner-about-content__content'>
            <h1 className='about-main__title'>
              ТРК «АРКТИЧЕСКИЙ» <span>—</span> <br />
              сообщество развития туризма!
            </h1>
            <div>
              <div>
                {!isMobile && (
                  <button onClick={handelPortal} className='first-block__become-member btn '>
                    стать участником
                  </button>
                )}
              </div>
              <div className='first-block__text-container'>
                <p>
                  Привет из таймырской Арктики! <br />
                  <br />
                  Туристско-рекреационный кластер «Арктический» (ТРК «Арктический») — это объединение людей,
                  заинтересованных в развитии туризма, и одновременно колоссальная территория с богатым потенциалом.
                  Кластер находится на севере Красноярского края, на полуострове Таймыр. Он охватывает туроператоров, экскурсионные бюро, сертифицированных гидов и экскурсоводов, а также мастеров, занимающихся изготовлением сувенирной продукции.
                  <br />
                  <br />
                  Последние годы арктический туризм становится все более популярным среди путешественников из разных
                  стран. И мы точно знаем, в чем причина постоянно растущего интереса. Север — многоликая земля с
                  безграничными возможностями для незабываемых путешествий.<br />
                  <br />
                  До встречи в таймырской Арктике!
                </p>
              </div>

              {isMobile && (
                <button onClick={handelPortal} className='first-block__become-member btn '>
                  стать участником
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*{isMobile && (
        <div className='about-main-slider'>
          <Slider
            {...settingsSliderMobile}
            className={classNames('cluster-member__news__slider')}
            beforeChange={handleChangeSlide}
            onInit={handleInitSlider}
          >
            {aboutNewsList.map((info) => (
              <AboutMainInfoCardMobile key={info.id} info={info} />
            ))}
          </Slider>
        </div>
      )}*/}
    </>
  );
};
