import { Edit, SimpleForm, TextInput } from "react-admin";

export const TourUpcomingEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" label="Название тура" />
      <TextInput source="description" label="Описание" multiline />
    </SimpleForm>
  </Edit>
);
