import React, { useState } from 'react';
import './DropdownMenu.scss';
import classnames from 'classnames';

type Props = {
  className?: string;
  items: { label: string; href: string }[];
  label?: string | JSX.Element;
  isWhiteLink?: boolean
};

export const DropdownMenu = ({ isWhiteLink, className, label, items }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`dropdown ${className}`}>
      <div className='dropdown__header' onClick={toggleMenu}>
        {label}
        <svg
          width='13'
          height='9'
          viewBox='0 0 13 9'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={`dropdown__arrow ${isOpen ? 'up' : 'down'}`}
        >
          <path d='M1.5 2L6.50376 7L11.5 2' stroke='currentColor' stroke-width='2' stroke-linecap='square' />
        </svg>
      </div>
      {isOpen && (
        <ul className='dropdown__menu'>
          {items.map((item) => (
            <li>
              <a href={item.href} className={classnames('dropdown__item', { white: isWhiteLink })}>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
