import { Create, SimpleForm, ReferenceInput, SelectInput, Button, useRefresh } from "react-admin";
import axios from "axios";

export const TourUpcomingCreate = () => {
  const refresh = useRefresh();

  const markAsUpcoming = (data) => {
    axios
      .patch(`/api/tours/${data.tourId}/upcoming`) // Отправляем запрос
      .then(() => {
        refresh(); // Обновляем список
      });
  };

  return (
    <Create>
      <SimpleForm>
        <ReferenceInput source="tourId" reference="tours">
          <SelectInput optionText='title'/>
        </ReferenceInput>
        <Button label="Добавить в ближайшие" onClick={markAsUpcoming} />
      </SimpleForm>
    </Create>
  );
};
 
