import { List, Datagrid, TextField, Button, useRefresh, useRecordContext, ShowButton, EditButton } from "react-admin";
import axios from "axios";

export const ToursUpcomingList = () => {
  const refresh = useRefresh();
 const record = useRecordContext();

  return (
    <List>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="title" label="Название тура" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
