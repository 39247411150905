import { TFiltersTours, TNamesParamsTourFilter } from 'shared/types/tours.types';
import { BaseSelect } from '../../../shared/ui/BaseSelect/BaseSelect';
import { ParamsFilterGroup, ParamsFilterItem } from 'features/shared';

const itemsMonthTour = [
  { value: '', label: 'Круглый год' },
  { value: '1', label: 'Январь' },
  { value: '2', label: 'Февраль' },
  { value: '3', label: 'Март' },
  { value: '4', label: 'Апрель' },
  { value: '5', label: 'Май' },
  { value: '6', label: 'Июнь' },
  { value: '7', label: 'Июль' },
  { value: '8', label: 'Август' },
  { value: '9', label: 'Сентябрь' },
  { value: '10', label: 'Октябрь' },
  { value: '11', label: 'Ноябрь' },
  { value: '12', label: 'Декабрь' },
];

type TToursFiltersBySeasonProps = {
  filters: number[]; // Массив чисел для фильтрации месяцев
  onClick: <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours[TName]) => void;
};

export const ToursFiltersBySeason = ({ filters, onClick }: TToursFiltersBySeasonProps) => {
  const checkActiveMonth = (value: string) => {
    if (value === '') {
      // Проверка для "Круглый год"
      return filters.length === 0;
    }
    const monthValue = parseInt(value, 10);
    return filters.includes(monthValue);
  };

  const handleClick = (value: string) => {
    if (value === '') {
      // Если выбран "Круглый год", очищаем фильтр по месяцам
      onClick('months', []);
    } else {
      const monthValue = parseInt(value, 10);
      const updatedFilters = filters.includes(monthValue)
        ? filters.filter((item) => item !== monthValue) // Удаляем месяц
        : [...filters, monthValue]; // Добавляем месяц
      onClick('months', updatedFilters); // Обновляем состояние
    }
  };

  return (
    <ParamsFilterGroup title='Месяц'>
      {itemsMonthTour.map((month) => (
        <ParamsFilterItem
          key={month.value}
          active={checkActiveMonth(month.value)} // Проверка активности
          onClick={() => handleClick(month.value)} // Обработка клика
          label={month.label}
        />
      ))}
    </ParamsFilterGroup>
  );
};