import {useEffect, useState} from "react";
import {FactBlogDTO, TBlog} from "../../../shared/types/types";
import {NavLink} from "react-router-dom";
import classnames from "classnames";

export type TNextBlogProps = {
    blog :TBlog
}

export const NextBlog = ({blog}: TNextBlogProps) => {
    const [showButton, setShowButton] = useState(false);
    const [isStatic, setIsStatic] = useState(false)
    useEffect(() => {
        function handleScroll() {
            const element = document.getElementById("endBlog");
            const elEnd = document.getElementById('endForNextBlog');

            let scrollHeight = Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );

            if (element && elEnd) {
                const rect = element.getBoundingClientRect();
                const isVisible = rect.top < window.innerHeight;
                const delta =  scrollHeight  - window.scrollY
                if(delta <= 1900 )
                    setIsStatic(true)
                else
                    setIsStatic(false)

                setShowButton(isVisible);
            }
        }

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // check on initial load

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (<>
            {showButton && <div className={classnames('nextBlog', { 'nextBlogStatic': isStatic  })}  >
                <div className="wrapNextBlog" >
                    <NavLink to={`/blog/${blog.id}`} className='redbtn'>
                        читайте также: {blog.name}
                    </NavLink>
                </div>
            </div>}
            {showButton && !isStatic && <div className='nextBlog nextBlogStatic hiddenBtnNext'   >
                <div className="wrapNextBlog" >
                    <NavLink to={`/blog/${blog.id}`} className='redbtn'>
                        читайте также: {blog.name}
                    </NavLink>
                </div>
            </div>}



        </>
    )
};
