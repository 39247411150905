import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { URL_FOR_IMG } from 'shared/constants/const';
import { TDesignerLocation, TLocation } from 'shared/types/location.types';
import { addItemToOrder, removeItemFromOrder, setCity, setLocation } from 'manageStore/designer/designer.slice';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { ReactComponent as InfoIcon } from 'assets/images/common/info.svg';
import { TourDesignerLocationsList } from '../TourDesignerLocationsList/TourDesignerLocationsList';
import { TRANSFORM_LOCATIONS } from 'shared/constants/locations.constants';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { selectLocationInOrder, selectPopularLocations } from 'manageStore/designer/designer.select';
import { useEffect, useState } from 'react';
import { TourDesignerGallery } from '../TourDesignerGallery/TourDesignerGallery';
import { Portal } from 'shared/lib/Portal/Portal';

type TTourDesignerLocationProps = {
  location: TDesignerLocation;
};

export const TourDesignerLocation = ({ location }: TTourDesignerLocationProps) => {
  const dispatch = useDispatch();
  const { name, description } = location;
  const inOrder = useAppSelector((state) => selectLocationInOrder(state, location.name));
  const popularLocations = useSelector(selectPopularLocations);

  const [popularInCity, setPopularInCity] = useState<TLocation[] | null>(null);
  const [isShowGallery, setIsShowGallery] = useState(false);

  const preview = 'preview' in location ? location.preview : location.photo;
  const urlPreview = preview?.path ? `${URL_FOR_IMG}/${preview.path}` : null;
  const descriptionSections = description ? description.split('\n') : [];

  const needGallery = !!location.gallery?.length;

  const linkBlog = 'guidebookId' in location && location.guidebookId ? `/travel-guides/${location.guidebookId}` : null;

  const transport =
    'availableTransfers' in location && location.availableTransfers?.length
      ? location.availableTransfers
          .map((item) => TRANSFORM_LOCATIONS.transport[item].label.toLocaleLowerCase())
          .join(', ')
      : '';

  let seasonText = '';
  if ('season' in location && location.season) {
    const { summer, winter, autumn, spring } = location.season;
    if (summer && winter && autumn && spring) {
      seasonText = 'круглый год';
    } else {
      let seasons = [];

      if (winter) seasons.push('Зима');
      if (spring) seasons.push('Весна');
      if (summer) seasons.push('Лето');
      if (autumn) seasons.push('Осень');

      seasonText = seasons.join('-');
    }
  }

  const handleClose = () => {
    if ('city' in location) {
      dispatch(setLocation(null));
    } else {
      dispatch(setCity(null));
    }
  };

  const removeFromOrder = () => {
    dispatch(removeItemFromOrder({ loc: location }));
  };

  const addToOrder = () => {
    dispatch(addItemToOrder(location));
  };

  const toggleGallery = () => {
    if (needGallery) {
      setIsShowGallery((prev) => !prev);
    }
  };

  useEffect(() => {
    if (!('city' in location)) {
      setPopularInCity(popularLocations.filter((item) => 'city' in item && item.city && item.city.id === location.id));
    } else {
      setPopularInCity(null);
    }
  }, [location, popularLocations]);
  console.info(seasonText);
  return (
    <>
      <div className='designer-location-item'>
        <div className='designer-location-item__header'>
          <CloseIcon className='designer-location-item__close' onClick={handleClose} />
          <h3 className='designer-location-item__title'>{name}</h3>
          <div className='designer-location-item__in-order'>
            {inOrder ? (
              <div className='in-order' onClick={removeFromOrder}>
                <div className='in-order__text'>Удалить из «Моего маршрута»</div>
              </div>
            ) : (
              <div className='in-order' onClick={addToOrder}>
                <div className='in-order__text'>Добавить в «Мой маршрут»</div>
              </div>
            )}
          </div>
        </div>
        <div className='designer-location-item__main'>
          {!!urlPreview && (
            <div className='designer-location-item__preview' onClick={toggleGallery}>
              <img src={urlPreview} alt='превью' />
              {!!location.gallery?.length && (
                <div className='designer-location-item__gallery'>{location.gallery?.length} фото</div>
              )}
            </div>
          )}
          {'city' in location && (
            <div className='designer-location-item__parameters'>
              <div className='location-parameters'>
                <div className='location-parameters__item'>
                  <div className='location-parameters__title'>Территория:</div>
                  <div className='location-parameters__value'>{location.cluster?.name}</div>
                </div>
                <div className='location-parameters__item'>
                  <div className='location-parameters__title'>Как добраться:</div>
                  <div className='location-parameters__value'>{transport}</div>
                </div>
                {!!seasonText && (
                  <div className='location-parameters__item'>
                    <div className='location-parameters__title'>Сезон:</div>
                    <div className='location-parameters__value'>{seasonText}</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!!linkBlog && (
            <div className='designer-location-item__to-blog'>
              <div className='to-blog'>
                <InfoIcon className='to-blog__icon' />
                <div className='to-blog__text'>
                  Узнайте больше в наших <Link to={linkBlog}>статьях и обзорах</Link>
                </div>
              </div>
            </div>
          )}
          <div className='designer-location-item__description'>
            {descriptionSections.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
          {!!popularInCity?.length && (
            <div className='designer-location-item__popular'>
              <h3 className='designer-location-item__title'>Популярные локации</h3>
              <TourDesignerLocationsList locations={popularInCity} />
            </div>
          )}
        </div>
      </div>
      <Portal
        isOpen={isShowGallery}
        onClose={toggleGallery}
        classContainer='designer-location-item__gallery-popup'
        classOverlay='designer-location-item__gallery-overlay'
      >
        {!!location.gallery && (
          <TourDesignerGallery onClose={toggleGallery} images={location.gallery} title={location.name} />
        )}
      </Portal>
    </>
  );
};
