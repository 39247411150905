import {
  Datagrid,
  List,
  NumberField,
  TextField,
  Pagination,
  useRecordContext,
  SearchInput,
  TextInput, useListContext,
} from 'react-admin';
import { MENU_ADMIN_LIST, TOURS_FIELDS, STATUS_TYPE } from '../../../shared/constants/const';
import { tr } from 'date-fns/locale';

const PublicField = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span className='publicField'>{record.isPublished ? 'Опубликован' : 'Приостановлен'} </span>;
};

const GeneratedLinkField = () => {
  const record = useRecordContext();
  if (!record || !record.id) return null;

  const link = `${window.location.origin}/tours/${record.engName}`;

  return record.isPublished ? (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      {link}
    </a>
  ) : (
    <span style={{ color: 'gray', cursor: 'not-allowed' }}>{link}</span>
  );
};

const postFilters = [
  <SearchInput source='q' alwaysOn resettable={false} />,
  <TextInput label='Title' source='title' defaultValue='Hello' />,
];

const ToursStatsList = () => {
  return (
    <List
      title={MENU_ADMIN_LIST.tours}
      filters={postFilters}
      exporter={false}
      pagination={<Pagination />}
    >
      <Datagrid
        bulkActionButtons={false}
        sx={{
          '& .RaDatagrid-rowCell': { padding: '2px 15px', height: '50px' },
        }}
      >
        <TextField source='title' sortable={true} label={TOURS_FIELDS.title} />
        <GeneratedLinkField label={TOURS_FIELDS.link} />
        <PublicField source='isPublished' label={TOURS_FIELDS.statusPublic} />
        <NumberField source='countOpen' sortable={true} label={TOURS_FIELDS.countOpen} />
        <NumberField source='countDetails' sortable={true} label={TOURS_FIELDS.countDetails} />
      </Datagrid>
    </List>
  );
};

export default ToursStatsList;
