import React, { useMemo, useState } from 'react';
import { TLocation } from 'shared/types/location.types';
import { TravelGuideItemOsmMap } from './TravelGuideItemOsmMap';
import classNames from 'classnames';

type TTravelGuideItemMapProps = {
  location: TLocation;
  backColor: 'black' | 'beige' | 'blue';
  afterColor: 'white' | 'black' | 'blue';
  isCity: boolean;
};

export const TravelGuideItemMap = (props: TTravelGuideItemMapProps) => {
  const { location, backColor, afterColor, isCity } = props;

  const [isMapActive, setIsMapActive] = useState(false);

  const coords = useMemo(() => {
    if (!location) {
      return [69.346734, 88.224851];
    }
    return !!location.lng && !!location.lat ? [location.lat, location.lng] : [69.346734, 88.224851];
  }, [location]);

  return (
    <section className={`travel-guide-item-map ${backColor}`}>
      <div className='container travel-guide-item-map__container'>
        <div className='travel-guide-item-map__title'>место на карте</div>
        <div className={`travel-guide-item-map__map ${afterColor}`}>
          <TravelGuideItemOsmMap location={coords} isMapActive={isMapActive} />
          <button
            className={classNames('path-tour-map__toggle-active', { active: isMapActive })}
            type='button'
            onClick={() => setIsMapActive((prev) => !prev)}
          >
            <span>{isMapActive ? 'Отключить карту' : 'Включить карту'}</span>
          </button>
        </div>
      </div>
    </section>
  );
};
