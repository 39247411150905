import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrderLocations } from 'manageStore/designer/designer.select';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
import { Portal } from 'shared/lib/Portal/Portal';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';

export const TourDesignerLearn = () => {
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const alreadyShowRef = useRef(false);
  const [isShow, setIsShow] = useState(false);

  const orderLocations = useSelector(selectOrderLocations);

  const closePortal = () => {
    setIsShow(false);
  };

  useEffect(() => {
    if (alreadyShowRef.current) {
      setIsShow(false);
    } else {
      const isAmptyOrder = !Object.keys(orderLocations).length;
      if (isAmptyOrder) {
        setIsShow(true);
      } else {
        alreadyShowRef.current = true;
        setIsShow(false);
      }
    }
  }, [orderLocations]);

  return (
    <Portal isOpen={isShow} onClose={closePortal} isBottom={isMobile}>
      <div className='designer-learn'>
        <div className='designer-learn__header'>
          Конструктор маршрутов
        </div>
        <p className='designer-learn__annotation'>
          Здесь вы можете собрать и заказать свое собственное путешествие! Найдите на карте интересный для вас объект,
          нажмите и добавьте его к себе в "Мой маршрут"
        </p>
        <button className='designer-learn__button' type='button' onClick={closePortal}>
          Понятно
        </button>
      </div>
    </Portal>
  );
};
