import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';



export type ImproveProductPopupProps = {
  close?: () => void;
}

export function ImproveProductPopup(props: ImproveProductPopupProps){

  return <div className={'improve-popup__frame'}>

    <button className={'improve-popup__close-btn'}
      type='button'
      onClick={props.close}
    >
      <CloseIcon className='close-icon' />
    </button>

    <h5 className={'improve-popup__header'}>
      Помогите нам стать лучше
    </h5>

    <div style={{ height: 19 }} />

    <div className={'improve-popup__description'}>
      Мы стремимся сделать для вас удобно
      и интересно, просим помочь нам
      и принять участие в исследовании
    </div>

    <div style={{ flex: 1 }} />

     <a href={`https://forms.yandex.ru/u/663a68c4505690f0050743c1/`}
      target='_blank'
      style={{ display: 'contents' }} rel="noreferrer"
    >
      <button className={'improve-popup__accept-btn'}
        type='button'
      >
        Принять участие
      </button>
      </a>
    </div>;
};
