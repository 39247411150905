import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { defaultFiltersTours, setFilters } from 'manageStore/tours/tours.slice';
import { selectLoading, selectSortedTours } from 'manageStore/tours/tours.select';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import Loading from 'components/Loading/Loading';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { declensionNoun } from 'helpers/declensionNoun';
import { TNamesTypeTourFilter, TTour } from 'shared/types/tours.types';
import { HomeToursItem } from 'entities/tour/HomeToursItem/HomeToursItem';
import { ToursFilters } from 'features';

export const HomeTours = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const [filters, setLocalFilters] = useState(defaultFiltersTours);
  const [cards, setCards] = useState<TTour[]>([]);

  const tours = useAppSelector((state) => selectSortedTours(state, filters));

  const textButton =
    filters.types.length || filters.locations.length
      ? `${tours.length} ${declensionNoun(tours.length, 'product')} в категории`
      : 'Все предложения';

  const changeFiltersTours = (type: string, fieldFilters: TNamesTypeTourFilter) => {
    setLocalFilters(type ? { ...defaultFiltersTours, [fieldFilters]: [type] } : { ...defaultFiltersTours });
  };

  const handleShowProducts = () => {
    dispatch(setFilters(filters));
    const newTab = window.open('/tours', '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      console.error('Failed to open new tab. It might be blocked by the browser.');
    }
  };

  useEffect(() => {
    setCards(tours.slice(0, 3));
  }, [filters, tours]);

  return (
    <section className='home-tours'>
      <div className='container home-tours__container'>
        <TitleSectionMain className='home-tours__title' title='Туры и экскурсии' />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <ToursFilters filters={filters} changeFiltersTours={changeFiltersTours} />
            <div className='home-tours__list'>
              {cards.length ? (
                cards.map((card) => <HomeToursItem key={card.id} tour={card} />)
              ) : (
                <>По выбранным параметрам туры пока отсутствуют.</>
              )}
            </div>
            <button className='home-tours__btn' onClick={handleShowProducts} disabled={!tours.length}>
              {textButton}
              <ArrowButton />
            </button>
          </>
        )}
      </div>
    </section>
  );
};
