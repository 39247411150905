import { Show, SimpleShowLayout, TextField } from "react-admin";

export const ToursUpcomingShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="title" label="Название тура" />
      <TextField source="shortDesc" label="Описание" />
    </SimpleShowLayout>
  </Show>
);
