import { createAsyncThunk } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import { setLoading, setTours } from './tours.slice';
import TourService from 'services/tour.service';
import { TFiltersTours } from 'shared/types/tours.types';

export const fetchTours = createAsyncThunk('fetchTours', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await TourService.getTours();
    dispatch(setTours(data));
  } catch (e) {
    const errorMessage = handleError(e);
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
});

// Новый thunk для загрузки туров с учетом фильтров
export const fetchToursWithFilters = createAsyncThunk('fetchToursWithFilters', async (filters: TFiltersTours, { dispatch }) => {
  dispatch(setLoading(true)); // Устанавливаем состояние загрузки
  try {
    // Передача фильтров в API
    const { data } = await TourService.getTours();
    dispatch(setTours(data)); // Сохраняем полученные туры
  } catch (e) {
    const errorMessage = handleError(e); // Обработка ошибок
    Notification.error(errorMessage);
    throw e;
  } finally {
    dispatch(setLoading(false)); // Сбрасываем состояние загрузки
  }
}
);
