import { useOsmMap } from 'widgets/shared/Maps/hooks/useOsmMap';
import { useAddPoints } from 'widgets/shared/Maps/hooks/useAddPoints';
import { OsmMap } from 'widgets/shared/Maps/lib/OsmMap';
import { getOsmViewOptions } from 'widgets/shared/Maps/helpers/getOsmViewOptions';
import React, { useMemo } from 'react';
import { useMapActiveState } from 'widgets/shared/Maps/hooks/useMapActiveState';
import { YMap } from 'widgets/shared/Maps/lib/YMap';

type TTravelGuideItemOsmMapProps = {
  location: number[];
  isMapActive?: boolean;
};

export const TravelGuideItemOsmMap = (props: TTravelGuideItemOsmMapProps) => {
  const { location, isMapActive = false } = props;

  const viewOptions = getOsmViewOptions({ zoom: 12, center: location });

  const clusterData = useMemo(() => [{ coords: location }], location);

  const [map] = useOsmMap('osm-map', viewOptions, { controls: [], interactions: [] });
  useMapActiveState(map, isMapActive);
  useAddPoints(map, clusterData, 'travelGuide');

  return <YMap pointsMap={clusterData} isActiveMap={isMapActive} />;
};
