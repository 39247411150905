import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { Admin, Resource, defaultTheme } from 'react-admin';
import adminApi from 'http/adminApi';
import adminService from 'services/admin.service';
import { TRANSFORM_TEXT } from '../../shared/constants/const';

import UsersList from 'components/Admin/Users/UsersList';
import ToursList from 'components/Admin/Tours/ToursList';
import { LocationsList } from 'components/Admin/Locations/LocationsList';
import NewsList from 'components/Admin/News/NewsList';
import BeautyList from 'components/Admin/Beauty/BeautyList';
import GalleryList from 'components/Admin/Gallery/GalleryList';
import LodgingList from 'components/Admin/Lodging/LodgingList';
import FoodList from 'components/Admin/Food/FoodList';
import AttractionList from 'components/Admin/Attraction/AttractionList';
import { CityList } from 'components/Admin/City/CityList';
import ToursShow from 'components/Admin/Tours/ToursShow';
import LocationsShow from 'components/Admin/Locations/LocationsShow';
import NewsShow from 'components/Admin/News/NewsShow';
import BeautyShow from 'components/Admin/Beauty/BeautyShow';
import GalleryShow from 'components/Admin/Gallery/GalleryShow';
import LodgingShow from 'components/Admin/Lodging/LodgingShow';
import FoodShow from 'components/Admin/Food/FoodShow';
import AttractionShow from 'components/Admin/Attraction/AttractionShow';
import { CityShow } from 'components/Admin/City/CityShow';
import { LocationsCreate } from 'components/Admin/Locations/LocationsCreate';
import { LocationsEdit } from 'components/Admin/Locations/LocationsEdit';
import NewsCreate from 'components/Admin/News/NewsCreate';
import NewsEdit from 'components/Admin/News/NewsEdit';
import BeautyCreate from 'components/Admin/Beauty/BeautyCreate';
import BeautyEdit from 'components/Admin/Beauty/BeautyEdit';
import GalleryCreate from 'components/Admin/Gallery/GalleryCreate';
import GalleryEdit from 'components/Admin/Gallery/GalleryEdit';
import LodgingCreate from 'components/Admin/Lodging/LodgingCreate';
import LodgingEdit from 'components/Admin/Lodging/LodgingEdit';
import FoodCreate from 'components/Admin/Food/FoodCreate';
import FoodEdit from 'components/Admin/Food/FoodEdit';
import AttractionCreate from 'components/Admin/Attraction/AttractionCreate';
import AttractionEdit from 'components/Admin/Attraction/AttractionEdit';
import { CityCreate } from 'components/Admin/City/CityCreate';
import { CityEdit } from 'components/Admin/City/CityEdit';
import UserEdit from 'components/Admin/Users/UserEdit';
import UserShow from 'components/Admin/Users/UserShow';
import UserCreate from 'components/Admin/Users/UserCreate';
import OperatorList from 'components/Admin/Operator/OperatorList';
import OperatorShow from 'components/Admin/Operator/OperatorShow';
import MessageList from 'components/Admin/Message/MessageList';
import AdminLayout from 'components/Admin/Layout/AdminLayout';
import NpaList from 'components/Admin/Npa/NpaList';
import NpaCreate from 'components/Admin/Npa/NpaCreate';
import { PartnersList } from 'components/Admin/Partners/PartnersList';
import { PartnersShow } from 'components/Admin/Partners/PartnersShow';
import { PartnersCreate } from 'components/Admin/Partners/PartnersCreate';
import { PartnersEdit } from 'components/Admin/Partners/PartnersEdit';
import { QuestionsList } from 'components/Admin/Questions/QuestionsList';
import { QuestionsShow } from 'components/Admin/Questions/QuestionsShow';
import { QuestionsCreate } from 'components/Admin/Questions/QuestionsCreate';
import { QuestionsEdit } from 'components/Admin/Questions/QuestionsEdit';
import { GuideList } from 'components/Admin/Guide/GuideList';
import { GuideEdit } from 'components/Admin/Guide/GuideEdit';
import GuideShow from 'components/Admin/Operator/GuideShow';
import { GuideCreate } from 'components/Admin/Guide/GuideCreate';
import BlogEdit from "../../components/Admin/Blog/BlogEdit";
import BlogShow from "../../components/Admin/Blog/BlogShow";
import BlogCreate from "../../components/Admin/Blog/BlogCreate";
import BlogList from "../../components/Admin/Blog/BlogList";
import TrKMemberList from "../../components/Admin/trk-member/TrKMemberList";
import TrKMemberEdit from "../../components/Admin/trk-member/TrKMemberEdit";
import TrKMemberCreate from "../../components/Admin/trk-member/TrKMemberCreate";
import TrKMemberShow from "../../components/Admin/trk-member/TrKMemberShow";
import ParticipantsList from "../../components/Admin/Participants/ParticipantsList";
import ParticipantsEdit from "../../components/Admin/Participants/ParticipantsEdit";
import ParticipantsShow from "../../components/Admin/Participants/ParticipantsShow";
import ParticipantsCreate from "../../components/Admin/Participants/ParticipantsCreate";
import { ClustorList } from "../../components/Admin/Cluster/ClustorList";
import { ClustorEdit } from "../../components/Admin/Cluster/ClustorEdit";
import { ClustorShow } from "../../components/Admin/Cluster/ClustorShow";
import { ClustorCreate } from "../../components/Admin/Cluster/ClustorCreate";
import { PageSettingsList } from 'components/Admin/PageSettings/PageSettingsList';
import { PageSettingsCreate } from "components/Admin/PageSettings/PageSettingsCreate";
import { PageSettingsEdit } from "components/Admin/PageSettings/PageSettingsEdit";
import TourShow from 'components/Account/ManageTour/TourShow';
import { ToursUpcomingList } from 'components/Admin/ToursUpcoming/ToursUpcomingList';
import { ToursUpcomingShow } from 'components/Admin/ToursUpcoming/ToursUpcomingShow';
import { TourUpcomingCreate } from 'components/Admin/ToursUpcoming/TourUpcomingCreate';
import { TourUpcomingEdit } from 'components/Admin/ToursUpcoming/TourUpcomingEdit';
import ToursStatsList from '../../components/Admin/ToursStats/ToursStatsList';

const myTheme = {
  ...defaultTheme,
  breakpoints: {
    // ...defaultTheme.brebreakpoints,
    values: {
      xl: 1536,
      lg: 1400,
      md: 1350,
      sm: 1350,
      xs: 0,
    },
  },
  sidebar: {
    ...defaultTheme.sidebar,
    closedWidth: 0,
  },
};

const { admin, superAdmin } = TRANSFORM_TEXT.role;

export const AdminPage = () => {
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const history = useHistory();

  useEffect(() => {
    if (!isAuth || !userData) {
      history.push('/login');
    } else if (userData.role !== admin && userData.role !== superAdmin) {
      history.push('/account');
    }
  }, [isAuth, userData, history]);

  return (
    <Admin
      title='Туркластер Арктический'
      theme={myTheme}
      layout={AdminLayout}
      authProvider={adminService}
      dataProvider={adminApi}
      disableTelemetry
    >
      <Resource name='user' list={UsersList} edit={UserEdit} show={UserShow} create={UserCreate} />
      <Resource name='operator' list={OperatorList} show={OperatorShow} />
      <Resource name='tours' list={ToursList} show={ToursShow} edit={TourShow} create={TourShow} />
      <Resource name='tours-stats' list={ToursStatsList} />
      <Resource name='toursUpcoming' list={ToursUpcomingList} show={ToursUpcomingShow} edit={TourUpcomingEdit} create={TourUpcomingCreate} />
      <Resource name='partners' list={PartnersList} show={PartnersShow} edit={PartnersEdit} create={PartnersCreate} />
      <Resource name='questions' list={QuestionsList} show={QuestionsShow} edit={QuestionsEdit} create={QuestionsCreate} />
      <Resource name='page-settings' list={PageSettingsList} create={PageSettingsCreate} edit={PageSettingsEdit} />
      <Resource name='feedback' list={MessageList} />
      <Resource
        name='locations'
        list={LocationsList}
        edit={LocationsEdit}
        show={LocationsShow}
        create={LocationsCreate}
      />
      <Resource name='guidebook' list={GuideList} edit={GuideEdit} show={GuideShow} create={GuideCreate} />
      <Resource name='news' list={NewsList} edit={NewsEdit} show={NewsShow} create={NewsCreate} />
      <Resource name='blog' list={BlogList} edit={BlogEdit} show={BlogShow} create={BlogCreate} />
      <Resource name='beauty' list={BeautyList} edit={BeautyEdit} show={BeautyShow} create={BeautyCreate} />
      <Resource name='tape-participants' list={ParticipantsList} edit={ParticipantsEdit} show={ParticipantsShow} create={ParticipantsCreate} />
      <Resource name='gallery' list={GalleryList} edit={GalleryEdit} show={GalleryShow} create={GalleryCreate} />
      <Resource name='lodging' list={LodgingList} edit={LodgingEdit} show={LodgingShow} create={LodgingCreate} />
      <Resource name='food' list={FoodList} edit={FoodEdit} show={FoodShow} create={FoodCreate} />
      <Resource
        name='attraction'
        list={AttractionList}
        edit={AttractionEdit}
        show={AttractionShow}
        create={AttractionCreate}
      />
      <Resource name='city' list={CityList} edit={CityEdit} show={CityShow} create={CityCreate} />
      <Resource name='inddoc' list={NpaList} create={NpaCreate} />
      <Resource
        name='trk-member'
        list={TrKMemberList}
        edit={TrKMemberEdit}
        create={TrKMemberCreate}
        show={TrKMemberShow}
      />
      <Resource name='cluster'
        list={ClustorList}
        edit={ClustorEdit}
        show={ClustorShow}
        create={ClustorCreate}
      />

    </Admin>
  );
};
