import { URL_FOR_IMG } from 'shared/constants/const';
import { TDayTour } from 'shared/types/tours.types';

export const TourItemProgram = ({ days }: { days: TDayTour[] | null }) => {
  if (!days || !days.length) {
    return null;
  }

  return (
    <div className='program-tour'>
      <div className='program-tour__title'>Программа</div>
      <div className='days-list'>
        {days.map((day, index) => (
          <div className='day-item' key={day.id}>
            <div className='day-item__header'>
              {!!day.numberDay?.trim() && <div className='day-item__number'>{day.numberDay}</div>}
              <img className='day-item__image' src={`${URL_FOR_IMG}/${day.photo?.path}`} alt={`${index + 1} День`} />
            </div>
            <div className='day-item__content'>
              <div className='day-item__title'>{day.title}</div>
              <div className='day-item__subtitle'>{day.desc}</div>
              <div className='day-item__timeline'>
                {day.timeline?.split('\n').map((time, index) => (
                  <p key={index}>{time}</p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
