import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { setFilters } from 'manageStore/tours/tours.slice';
import { selectFiltersTours, selectLoading, selectSortedTours } from 'manageStore/tours/tours.select';
import Loading from 'components/Loading/Loading';
import { TFiltersTours, TNamesTypeTourFilter } from 'shared/types/tours.types';
import { TourCard } from 'entities/tour/TourCard/TourCard';
import { ToursFilters } from 'features';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import UpcomingTours from 'components/UpcomingTours/UpcomingTours';

const filterTypeSearchName: TNamesTypeTourFilter = 'types';
const filterLocationSearchName: TNamesTypeTourFilter = 'locations';

export const ToursMain = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const filters = useSelector(selectFiltersTours);
  const tours = useAppSelector((state) => selectSortedTours(state, filters));
  const uncoming = tours.filter(tour => tour.isUpcoming)
  const { replace } = useHistory();
  const { pathname, search, hash } = useLocation();
  const searchParams = new URLSearchParams(search);

  // set types & locations filter from url to redux
  const filterTypes = searchParams.getAll(filterTypeSearchName);
  const filterLocations = searchParams.getAll(filterLocationSearchName);
  useEffect(() => {
    dispatch(
      setFilters({
        ...filters,
        types: filterTypes,
        locations: filterLocations,
      })
    );
  }, [filterTypes.join(','), filterLocations.join(',')]);

  

  // set types & locations filter from user to url (rest directly to redux)
  const setFiltersToUrl = (newFilters: TFiltersTours) => {
    dispatch(
      setFilters({
        ...newFilters,
        types: filters.types,
        locations: filters.locations,
      })
    );
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.delete(filterTypeSearchName);
    newSearchParams.delete(filterLocationSearchName);
    newFilters.types.forEach((f) => newSearchParams.append(filterTypeSearchName, f));
    newFilters.locations.forEach((f) => newSearchParams.append(filterLocationSearchName, f));
    replace({
      pathname: pathname,
      search: `?${newSearchParams.toString()}${hash}`
    });
    window.scrollTo(document.documentElement.scrollLeft, document.documentElement.scrollTop)
  };

  const changeFiltersTours = (filter: string, filterType: TNamesTypeTourFilter) => {
    const newSearchParams = new URLSearchParams(search);

    if (filter) {
      newSearchParams.has(filterType) ?
        newSearchParams.set(filterType, filter):
        newSearchParams.append(filterType, filter);
    } else {
      newSearchParams.delete(filterTypeSearchName);
      newSearchParams.delete(filterLocationSearchName);
    }

    replace({
      pathname,
      search: `?${newSearchParams.toString()}${hash}`
    });
    window.scrollTo(document.documentElement.scrollLeft, document.documentElement.scrollTop)
  };

  return (
    <main className='tours'>
      <div className='container tours__container'>
        <h1 className='tours__title'>Туры и экскурсии</h1>
       {uncoming.length > 0 && <UpcomingTours tours={uncoming} />}
        <div>
          <ToursFilters
            filters={filters}
            changeFiltersTours={changeFiltersTours}
            setFiltersToUrl={setFiltersToUrl}
            withParams
          />
          {loading ? (
            <Loading />
          ) : tours?.length ? (
            <div className='tours__list-wrapper'>
              <div className='tours-list'>
                {tours.map((tour) => (
                  <TourCard key={tour.id} tour={tour} />
                ))}
              </div>
            </div>
          ) : (
            <div className='no-results-text'>По выбранным параметрам туры пока отсутствуют.</div>
          )}
        </div>
      </div>
    </main>
  );
};
