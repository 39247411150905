import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import Loading from 'components/Loading/Loading';
import { TourItemTopSection } from 'widgets/lib/tourItemMain/TourItemTopSection/TourItemTopSection';
import { TourItemBenefits } from 'widgets/lib/tourItemMain/TourItemBenefits/TourItemBenefits';
import { TourItemProgram } from 'widgets/lib/tourItemMain/TourItemProgram/TourItemProgram';
import { TourItemPath } from 'widgets/lib/tourItemMain/TourItemPath/TourItemPath';
import { TourItemGallery } from 'widgets/lib/tourItemMain/TourItemGallery/TourItemGallery';
import { TourItemFaq } from 'widgets/lib/tourItemMain/TourItemFaq/TourItemFaq';
import { TourItemRecommendations } from 'widgets/lib/tourItemMain/TourItemRecommendations/TourItemRecommendations';
import { TTour } from 'shared/types/tours.types';
import TourService from 'services/tour.service';
import { handleError } from 'http/handleError';
import Notification from 'shared/lib/notification';
import { TourReadyApplication } from 'features';

export const TourItemMain = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const [openPortal, setOpenPortal] = useState(false);
  const handlePortal = useCallback(() => setOpenPortal((prev) => !prev), [setOpenPortal]);
  const [tour, setTour] = useState<TTour | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (params.id && !tour) {
      const fetchTour = async () => {
        try {
          const { data } = await TourService.getToursById(params.id);
          setTour(data);
        } catch (e) {
          const errorMessage = handleError(e);
          Notification.error(errorMessage);
          throw e;
        } finally {
          setLoading(false);
        }
      };
      fetchTour();
    }
  }, [params.id, tour]);

  useEffect(() => {
    if (!isLoading && !tour) {
      history.push('/tours');
    }
  }, [tour, isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  if (!tour) {
    return null;
  }

  return (
    <main className='tour-item'>
      <TourItemTopSection tourItem={tour} handlePortal={handlePortal} />
      <section className='tour-item__description'>
        <div className='container tour-item__container'>
          <div className='tour-item__description-content'>
            <div className='tour-item__description-title'>вас ждет</div>
            <div className='tour-item__description-text'>{tour.shortDesc}</div>
            <TourItemBenefits benefits={tour.benefits} />
          </div>
        </div>
      </section>
      <section className='tour-item__program-tour'>
        <div className='container tour-item__container tour-item__program-tour__container'>
          <TourItemProgram days={tour.days} />
        </div>
      </section>
      <section className='tour-item__path'>
        <div className='container tour-item__path__container'>
          <div className='tour-item__path__title'>Маршрут</div>
          <TourItemPath locations={tour.locations} />
        </div>
      </section>
      <section className='tour-item__photo'>
        <div className='container tour-item__photo__container'>
          <div className='tour-item__photo__title'>Фотогалерея</div>
          <TourItemGallery photos={tour.photos} />
        </div>
      </section>
      {
        (tour.included || tour.notIncluded || tour.additionalServices)  && (
          <section className="tour-item__faq">
            <div className="container tour-item__faq__container">
              <div className="tour-item__photo__title">вопросы и ответы</div>
              <TourItemFaq
                included={tour.included}
                notIncluded={tour.notIncluded}
                additionalServices={tour.additionalServices}
              />
            </div>
          </section>
        )
      }

      {!!tour.recommendedTours?.length && (
        <section className="tour-item__recommendations">
          <div className="container tour-item__recommendations__container">
            <div className="tour-item__recommendations__title">рекомендуемые туры</div>
            <TourItemRecommendations tours={tour.recommendedTours} />
          </div>
        </section>
      )}
      <section className='tour-item__to-designer'>
        <div className='container tour-item__container'>
          <Link className='tour-item__btn' to='/designer'>
            <span className='tour-item__btn-text'>Конструктор путешествий</span>
            <ArrowButton />
          </Link>
        </div>
      </section>
      <TourReadyApplication openPortal={openPortal} handlePortal={handlePortal} activeTour={tour} />
    </main>
  );
};
