import './homeFindTour.scss';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TFiltersTours } from 'shared/types/tours.types';
import { TRANSFORM_TEXT } from 'shared/constants/const';
import { defaultFiltersTours, setFilters } from 'manageStore/tours/tours.slice';
import { BaseSelect } from 'shared/ui/BaseSelect/BaseSelect';
import { selectFilters } from 'manageStore/tours/tours.select';
import { set } from 'date-fns';

type TName = keyof Omit<TFiltersTours, 'price'>;

const itemsTypeTour = [
  {
    value: '',
    label: 'Все туры и экскурсии',
  },
  {
    value: TRANSFORM_TEXT.productType.EXCURSION,
    label: TRANSFORM_TEXT.productType.EXCURSION,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.INDUSTRY,
    label: TRANSFORM_TEXT.tourTypeMulti.INDUSTRY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.WATER,
    label: TRANSFORM_TEXT.tourTypeMulti.WATER,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.HIKING,
    label: TRANSFORM_TEXT.tourTypeMulti.HIKING,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.HELICOPTER,
    label: TRANSFORM_TEXT.tourTypeMulti.HELICOPTER,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.SNOWMOBILE,
    label: TRANSFORM_TEXT.tourTypeMulti.SNOWMOBILE,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.JEEP,
    label: TRANSFORM_TEXT.tourTypeMulti.JEEP,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.COMBINED,
    label: TRANSFORM_TEXT.tourTypeMulti.COMBINED,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.ETHNIC,
    label: TRANSFORM_TEXT.tourTypeMulti.ETHNIC,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.CAMPING,
    label: TRANSFORM_TEXT.tourTypeMulti.CAMPING,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.EXPEDITIONARY,
    label: TRANSFORM_TEXT.tourTypeMulti.EXPEDITIONARY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.GASTRONOMIC,
    label: TRANSFORM_TEXT.tourTypeMulti.GASTRONOMIC,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.DIVING,
    label: TRANSFORM_TEXT.tourTypeMulti.DIVING,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.MOTORBIKE,
    label: TRANSFORM_TEXT.tourTypeMulti.MOTORBIKE,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.FAMILY,
    label: TRANSFORM_TEXT.tourTypeMulti.FAMILY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.PHOTOGRAPHY,
    label: TRANSFORM_TEXT.tourTypeMulti.PHOTOGRAPHY,
  },
  {
    value: TRANSFORM_TEXT.tourTypeMulti.TRAINING,
    label: TRANSFORM_TEXT.tourTypeMulti.TRAINING,
  },
];

const itemsSeasonTour = [
  {
    value: '',
    label: 'Круглый год',
  },
  {
    value: '1',
    label: 'Январь',
  },
  {
    value: '2',
    label: 'Февраль',
  },
  {
    value: '3',
    label: 'Март',
  },
  {
    value: '4',
    label: 'Апрель',
  },
  {
    value: '5',
    label: 'Май',
  },
  {
    value: '6',
    label: 'Июнь',
  },
  {
    value: '7',
    label: 'Июль',
  },
  {
    value: '8',
    label: 'Август',
  },
  {
    value: '9',
    label: 'Сентябрь',
  },
  {
    value: '10',
    label: 'Октябрь',
  },
  {
    value: '11',
    label: 'Ноябрь',
  },
  {
    value: '12',
    label: 'Декабрь',
  },
];

const itemsDurationTour = [
  { value: '', label: 'Не важно' },
  { value: '1-4', label: '1-4' },
  { value: '5-10', label: '5-10' },
  { value: '11-15', label: '11-15' },
  { value: '16-20', label: '16-20' },
  { value: '21-25', label: '21-25' },
  { value: '26-30', label: '26-30' },
];

export const HomeFindTour = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector(selectFilters);

  const [form, setForm] = useState<TFiltersTours>(defaultFiltersTours);

  const changeFormHandler = (name: TName, value: TFiltersTours[TName]) => {
    console.log(`Поле: ${name}, Значение:`, value);

    const field =
      name === 'months'
        ? { [name]: value ? [Number(value)] : [] } // Если months, преобразуем value в массив чисел
        : typeof form[name] === 'string'
          ? { [name]: value || 'Не выбрано' }
          : { [name]: value ? [value] : [] };

    setForm((prev) => {
      const updatedForm = { ...prev, ...field };
      return updatedForm;
    });
  };


  const findTour = () => {
    dispatch(setFilters(form));
    history.push({ pathname: 'tours' });
  };

  return (
    <div className='home-find-tour'>
      <div className='home-find-tour__background'></div>
      <BaseSelect
        title={'Вид отдыха'}
        items={itemsTypeTour}
        handlerSelect={(value: string) => changeFormHandler('types', value)}
      />
      <BaseSelect
        title={'Месяц'}
        items={itemsSeasonTour}
        handlerSelect={(value: string) => changeFormHandler('months', value)}
      />
      <BaseSelect
        title={'Количество дней'}
        items={itemsDurationTour}
        handlerSelect={(value: string) => changeFormHandler('duration', value)}
      />
      <button className='home-find-tour__btn' type='button' onClick={findTour}>
        Подобрать
      </button>
    </div>
  );
};
